import React, { useContext } from "react"
import { Flex, Heading, Button, Box } from "theme-ui"
import { ModalContext } from "./ModalManager"

export const Footer = () => {
  const { setModalType } = useContext(ModalContext)
  return (
    <Flex
      as="footer"
      sx={{
        bg: "primary",
        py: 1,
        px: [1, 2],
        // height: ["60px", "30px"],
        justifySelf: "flex-end",
      }}
    >
      <Heading
        sx={{ color: "background", fontSize: 3, flex: 1, alignSelf: "center" }}
      >
        Built by us.
      </Heading>
      <Box>
        <Button variant="outline" onClick={() => setModalType("privacy")}>
          Privacy Policy
        </Button>
      </Box>
    </Flex>
  )
}
