/** @jsx jsx */
import { jsx } from "@emotion/core"
import { useStaticQuery, graphql } from "gatsby"
import { Flex } from "theme-ui"
import { Logo } from "./Img"

export const Header = () => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          homeUrl
        }
      }
    }
  `)

  return (
    <Flex
      as="header"
      sx={{
        bg: "background",
        height: ["60px", "130px"],
        alignItems: "center",
        justifyContent: "center",
        my: 2,
        /* The next line tells the gatsby fluid image to work right */
        ".gatsby-image-wrapper": { width: ["60px", "90px"], height: "100%" },
      }}
    >
      <a href={data.site.siteMetadata.homeUrl}>
        <Logo />
      </a>
    </Flex>
  )
}
