import React from "react"
import { Helmet as ReactHelmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

interface Props {
  description?: string
  lang?: string
  meta?: any[]
  title?: string
}

export const Helmet: React.FC<Props> = ({
  description,
  lang = "en",
  meta = [],
  title = ``,
}) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            authors
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  return (
    <ReactHelmet
      htmlAttributes={{
        lang,
      }}
      title={title.length > 0 ? title : site.siteMetadata.title}
      // If the page has a custom title, append it to the site title.
      titleTemplate={`${title.length > 0 ? `%s | ` : ""}${
        site.siteMetadata.title
      }`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.authors[0],
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    />
  )
}
