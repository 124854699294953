import React from "react"
import { Header } from "./Header"
import { Footer } from "./Footer"
import { Box, Flex } from "theme-ui"
import { ModalManager } from "./ModalManager"
import { Helmet } from "./Helmet"

export const Layout: React.FC<{
  wide?: boolean
  title?: string
  embed?: boolean
}> = ({ wide = false, embed = false, title = ``, children }) => {
  return (
    <React.Fragment>
      <Helmet title={title} />

      <Flex sx={{ minHeight: "100vh", flexDirection: "column" }}>
        <ModalManager />
        {!embed && <Header />}
        <Box
          sx={{
            width: ["100%", wide ? "1080px" : "500px"],
            mx: "auto",
            px: [3, 0],
            pb: 4,
            flexGrow: 1,
          }}
        >
          <Box as="main" sx={{ height: "100%" }}>
            {children}
          </Box>
        </Box>
        {!embed && <Footer />}
      </Flex>
    </React.Fragment>
  )
}
